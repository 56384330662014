import axios from 'axios';
import React, { useEffect, useState } from 'react'
import {  Link } from 'react-router-dom'
import ReactApexChart from 'react-apexcharts';
// import { PieChart, Pie} from 'recharts';
import DatePicker from "react-datepicker";
import loading from '../../../images/spin.gif'
import filterdrop from '../../../images/filterdrop-icon.png'
import humburgor from '../../../images/humburgor.png'
import closeicon from '../../../images/close.png'

import {
  AreaChart,
  Area,
  YAxis,
  XAxis,
  CartesianGrid,
  Tooltip,
  Legend
  } from "recharts";
import Chart from 'react-apexcharts'
import "react-datepicker/dist/react-datepicker.css";
import styles from '../../../Dashboard.css';
import Dashboard_banner from './Dashboard_banner';
import { Helmet } from 'react-helmet';
const SERVER_URL = process.env.REACT_APP_SERVER_URL;
export default function Dashboard() {
  const API_PATH = `${SERVER_URL}dashboard.php`;
  const accountno = sessionStorage.getItem('accountno')
  const [orderreport, setorderreport] = useState({
    delivered: 0,
    deliveryout: 0,
    cutnotavailable: 0,
    returnshipment: 0,
    codpercent:0,
    prepaid:100,
    successpercent:100,
    estimated_percent:100

  })
  const [outfordelivery, setoutfordelivery] = useState()
  const [poddelivery, setpoddelivery] = useState()
  const [isload, setisload] = useState(true)
  useEffect(() => {

    axios({
      method: 'post',
      url: `${API_PATH}`,
      data: {accountno:accountno}
    })
      .then((res) => {
        console.log('HERE',res.data)
        setisload(false)
        setoutfordelivery(JSON.parse(res.data.outfordelivery))
        setpoddelivery(JSON.parse(res.data.podorders))
        setorderreport(JSON.parse(JSON.stringify(res.data)))
      }
      )
      .catch((err) => console.log(err))

  }, [])

  // const data = [
  //   { name: 'Prepaid', students: 200, fill: '#7f3d9d', text: 'xxxx' },
  //   { name: 'COD', students: 10, fill: "#ebe8e8", text: 'yyy' }
  // ];

  const data1 = {
    options: {
      plotOptions: {
        pie: {
          donut: {
            size: '80%'
          }
        }
      },
      fill: {
        colors: ['#7f3d9d', '#ebe8e8']
      }, chart: {
        type: 'donut',
      }, 
      labels: ['Pre-Paid', 'COD']
     
    },
    series: [orderreport.prepaid, orderreport.codpercent]
  }

  const data2 = {
    options: {
      plotOptions: {
        pie: {
          donut: {
            size: '80%'
          }
        }
      },
      fill: {
        colors: ['#7f3d9d', '#ebe8e8']
      }, chart: {
        type: 'donut',
      }, labels: ['First-time Delivery', 'NOT']
    },
    series: [orderreport.successpercent, 100-orderreport.successpercent]
  }
  const data3 = {
    options: {
      plotOptions: {
        pie: {
          donut: {
            size: '80%'
          }
        }
      },
      fill: {
        colors: ['#7f3d9d', '#ebe8e8']
      }, chart: {
        type: 'donut',
      }, labels: ['Estimated Attempt', 'NOT']
    },
    series: [orderreport.estimated_percent, 100-orderreport.estimated_percent]
  }

  // let basedate = new Date();
  // let today1 = new Date();
  // let day2 = new Date();
  // day2.setDate(day2.getDate()-1);
  // let day3 = new Date();
  // day3.setDate(day3.getDate()-2);


  
  function convdate(val){

    let today = new Date();
    today.setDate(today.getDate()-val);
    let dd = String(today.getDate()).padStart(2, '0');
    let mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    let yyyy = today.getFullYear();
    
    today = dd + '-' + mm + '-' + yyyy;
    return today
  }

   const [datesection, setdatesection] = useState({
    day1:`${convdate(7)}`,
    day2:`${convdate(6)}`,
    day3:`${convdate(5)}`,
    day4:`${convdate(4)}`,
    day5:`${convdate(3)}`,
    day6:`${convdate(2)}`,
    day7:`${convdate(1)}`,
   })


  //  var date = new Date();
  //  date.setDate(date.getDate() - 13);
   



  const graph=[
    {
       name: `${datesection.day1}`,
       "Out for Delivery": outfordelivery?outfordelivery[0]:0
    },
    {
       name: `${datesection.day2}`,
       "Out for Delivery": outfordelivery?outfordelivery[1]:0
    },
    {
       name: `${datesection.day3}`,
       "Out for Delivery": outfordelivery?outfordelivery[2]:0
    },
    {
      name: `${datesection.day4}`,
      "Out for Delivery": outfordelivery?outfordelivery[3]:0
   },
   {
    name: `${datesection.day5}`,
    "Out for Delivery": outfordelivery?outfordelivery[4]:0
 },
    {
       name: `${datesection.day6}`,
       "Out for Delivery": outfordelivery?outfordelivery[5]:0
    },
    {
       name: `${datesection.day7}`,
       "Out for Delivery": outfordelivery?outfordelivery[6]:0
    }
 ];
  
 const graph2=[
  {
     name: `${datesection.day1}`,
     "POD Orders": poddelivery?poddelivery[0]:0
  },
  {
     name: `${datesection.day2}`,
     "POD Orders": poddelivery?poddelivery[1]:0
  },
  {
     name: `${datesection.day3}`,
     "POD Orders": poddelivery?poddelivery[2]:0
  },
  {
    name: `${datesection.day4}`,
    "POD Orders": poddelivery?poddelivery[3]:0
 },
 {
  name: `${datesection.day5}`,
  "POD Orders": poddelivery?poddelivery[4]:0
},
  {
     name: `${datesection.day6}`,
     "POD Orders": poddelivery?poddelivery[5]:0
  },
  {
     name: `${datesection.day7}`,
     "POD Orders": poddelivery?poddelivery[6]:0
  }
];

const [filtersec, setfiltersec] = useState(false)
const date=new Date();
const date2=new Date();
   
let starts = date.setDate(date.getDate() - 7);
let enddate = date2.setDate(date2.getDate() - 1);

const [startDate, setStartDate] = useState(starts);
const [endDate, setendDate] = useState(enddate);
console.log(startDate)
function enablefiltersec(){
if(filtersec==true){
  setfiltersec(false)
}else{
  setfiltersec(true)
}
}

function changedate(date){
  setStartDate(date)
}
function changedate2(date){
  setendDate(date)
}

function submitfilter(){
  setisload(true)
  
  console.log(endDate)
  axios({
    method: 'post',
    url: `${API_PATH}`,
    data: {accountno:accountno,startDate:startDate,endDate:endDate}
  })
    .then((res) => {
      setisload(false)
      console.log(res.data)
      setoutfordelivery(JSON.parse(res.data.outfordelivery))
      setpoddelivery(JSON.parse(res.data.podorders))
      setorderreport(JSON.parse(JSON.stringify(res.data)))
    }
    )
    .catch((err) => console.log(err))
}

const [showmenu, setshowmenu] = useState(false)
const [menuicon, setmenuicon] = useState(`${humburgor}`)
function displayMenu(){
  if(showmenu==false){
    setshowmenu(true)
    setmenuicon(`${closeicon}`)
  }else{
    setshowmenu(false)
    setmenuicon(`${humburgor}`)
  }
  
}
  return (



    <div className="dashboard">
<Helmet>
        <title>Time Express | Dashboard</title>
      </Helmet>
<Dashboard_banner/>
    

     
      <section className="dashcls" style={{background:"#ededed"}}>
      
        <div className="row" style={{ padding: "4%",paddingBottom:"0px" }}>
         
        <div className="filtbox" >
          <p onClick={enablefiltersec}><img src={filterdrop} style={{background:" #8f44ad",padding:"4px"}}></img></p>
          {filtersec?(  
         
            <div className="row filtersec">
              <div className="date-holder">
          <DatePicker className="form-control shipping_date" name="shipping_date" dateFormat='dd/MM/yyyy' selected={startDate} onChange={date => {changedate(date);}} />
          </div>
          <div className="date-holder">
          <DatePicker className="form-control shipping_date" name="shipping_date" dateFormat='dd/MM/yyyy' selected={endDate} onChange={date => {changedate2(date);}} />
          </div>
          <button className="btn filterbtn" style={{background:"#fff"}} onClick={submitfilter}>Submit</button>
           
            </div>
      

          ):null}
        
          </div>
         
                                                               
          <div className="summary">
            <h3 style={{ textAlign: "center" }}>Daily & Monthly Summary</h3>

            <div className="priceing-cont">
              {isload?(<div id="loading"  />):null
              }
              
              <ul>
                <li className="bg_blue">
                  <h3>Total Orders Shipments<br /> Out for Delivery</h3> <span>{orderreport.deliveryout}</span></li>
                <li className="bg_orange"><h3>Delivered</h3> <span>{orderreport.delivered}</span></li>
                <li className="bg_chocolate "><h3>Delivery Estimated Attempts</h3> <span>{orderreport.estimated_percent}%</span></li>
                <li className="bg_purple"><h3>In Progress</h3> <span>{orderreport.cutnotavailable}</span></li>
                <li className="bg_lightblue"><h3>Return to Shipper</h3> <span>{orderreport.returnshipment}</span></li>
              </ul></div>
          </div>
        </div>
        <div className="row" style={{ padding: "4%",paddingTop:"0px" }}>
      
          <div className="pending-order-sec text-center">
         
              <div className="col-md-4 col-sm-4 pending-order-cont">
                <h6>Pre Paid/COD</h6>

                <div className="donut">
                  <Chart options={data1.options} series={data1.series} type="donut" width="280" style={{ margin: "auto" }} />
                </div>

                <div className="mt-4 text-center small">
                  <span className="mr-2">
                    <b className="per_val" id="codval" style={{ fontSize: '20px' }}>{orderreport.codpercent}%</b>
                  </span>
                </div>

                <div className="progress-graph position" data-percent="<?php echo $codpercent;?>" data-duration={1000} data-color="#ccc,#7f3f98" />
              </div>
              <div className="col-md-4 col-sm-4 pending-order-cont">
                <h6>Successful Delivery Attempt</h6>
                <div className="donut">
                  <Chart options={data2.options} series={data2.series} type="donut" width="280" style={{ margin: "auto" }} />
                </div>
                <div className="mt-4 text-center small">
                  <span className="mr-2">
                    <b className="per_val" id="codval" style={{ fontSize: '20px' }}>{orderreport.successpercent}%</b>
                  </span>
                </div>
                <div className="progress-graph position" data-percent="<?php echo $codpercent;?>" data-duration={1000} data-color="#ccc,#7f3f98" />
              </div>

              <div className="col-md-4 col-sm-4 pending-order-cont">
                <h6>Delivery Estimated Attempt</h6>
                <div className="donut">
                  <Chart options={data3.options} series={data3.series} type="donut" width="280" style={{ margin: "auto" }} />
                </div>
                <div className="mt-4 text-center small">
                  <span className="mr-2">
                    <b className="per_val" id="codval" style={{ fontSize: '20px' }}>{orderreport.estimated_percent}%</b>
                  </span>
                </div>
                <div className="progress-graph position" data-percent="<?php echo $codpercent;?>" data-duration={1000} data-color="#ccc,#7f3f98" />
              </div>
            </div>
        

        </div>
        {/* Area Graph */}
       

        <div className="row" style={{ padding: "4%",paddingTop:"0px" }}>
          <div className="pending-order-sec text-center">
            
              <div className="col-md-6 col-sm-6 pending-order-cont">
                <h6>Out For Delivery</h6>
                <AreaChart
               width={500}
               height={300}
               data={graph}
               margin={{ top: 10, right: 30, left: 0, bottom: 0 }}>
            <defs>
               <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="5%" stopColor="#d6a546" stopOpacity={0.8} />
                  <stop offset="95%" stopColor="#d6a546" stopOpacity={0} />
               </linearGradient>
               
            </defs>
            <XAxis dataKey="name" />
            <YAxis />
            <CartesianGrid strokeDasharray="3 3" />
            <Tooltip />
            <Legend />
           
            <Area
               type="monotone"
               dataKey="Out for Delivery"
               stroke="#d6a546"
               fillOpacity={1}
               fill="url(#colorUv)"/>
            </AreaChart>
                

                <div className="progress-graph position" data-percent="<?php echo $codpercent;?>" data-duration={1000} data-color="#ccc,#7f3f98" />
              </div>
              <div className="col-md-6 col-sm-6 pending-order-cont">
                <h6>Total Delivered</h6>
                <AreaChart
               width={500}
               height={300}
               data={graph2}
               margin={{ top: 10, right: 30, left: 0, bottom: 0 }}>
            <defs>
            <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="5%" stopColor="#3598dc" stopOpacity={0.8} />
                  <stop offset="95%" stopColor="#3598dc" stopOpacity={0} />
               </linearGradient>
            </defs>
            <XAxis dataKey="name" />
            <YAxis />
            <CartesianGrid strokeDasharray="3 3" />
            <Tooltip />
            <Legend />
            
            <Area
               type="monotone"
               dataKey="POD Orders"
               stroke="#3598dc"
               fillOpacity={1}
               fill="url(#colorPv)"/>
            </AreaChart>
                <div className="progress-graph position" data-percent="<?php echo $codpercent;?>" data-duration={1000} data-color="#ccc,#7f3f98" />
              </div>

            
        

        </div>
        </div>

      </section>

    </div>

  )
}
